import * as React from 'react';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { get } from 'lodash';

import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { Widget } from '../Widget/Widget';
import i18n from '../../config/i18n';

import { IWidgetRootProps } from './WidgetRoot.types';
import { addHighlightStyle, HiglightStyle } from './addHighlightStyle';
import {
  SearchDocumentType,
  ISearchDocumentType,
} from '@wix/client-search-sdk';
import { ISearchSample } from '../../platform/SearchResultsControllerStore.types';

const applyWhitelist = ({
  documentTypes = [],
  searchSamples = [],
}: {
  documentTypes: ISearchDocumentType[];
  searchSamples: ISearchSample[];
}) => {
  const categoryWhitelist = Object.values(SearchDocumentType);
  return {
    documentTypes: documentTypes.filter(({ documentType }) =>
      categoryWhitelist.includes(documentType),
    ),
    searchSamples: searchSamples.filter(({ documentType }) =>
      categoryWhitelist.includes(documentType),
    ),
  };
};

export class WidgetRoot extends React.PureComponent<IWidgetRootProps> {
  componentDidMount() {
    this.props.host?.appLoadBI.loaded();
    this.props.host?.registerToComponentDidLayout(this.props.onAppLoaded);
  }

  render() {
    const {
      searchResultsAbsoluteUrl,
      language,
      experiments,
      settings,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchResponseTotals,
      onQuerySubmit,
      onPageChange,
      onDocumentTypeChange,
      onDocumentClick,
      viewMode,
      isDemoContent,
      isMobile,
      sortConfig,
      reportError,
    } = this.props;

    console.groupCollapsed('WidgetRoot:render');
    console.log('props', this.props);
    console.log('settings', settings);
    console.groupEnd();

    const { documentTypes, searchSamples } = applyWhitelist({
      documentTypes: this.props.documentTypes,
      searchSamples: this.props.searchSamples,
    });

    const highlightStyle: HiglightStyle = get(
      this.props,
      [
        'host',
        'style',
        'styleParams',
        'fonts',
        'resultsHighlightFont',
        'style',
      ],
      {
        bold: false,
        italic: false,
        underline: false,
      },
    );

    this.props.searchResponse.documents.forEach(document => {
      document.title = addHighlightStyle(document.title, highlightStyle);
      document.description = addHighlightStyle(
        document.description,
        highlightStyle,
      );
    });

    searchSamples.forEach(sample =>
      sample.documents.forEach(document => {
        document.title = addHighlightStyle(document.title, highlightStyle);
        document.description = addHighlightStyle(
          document.description,
          highlightStyle,
        );
      }),
    );

    return (
      <I18nextProvider i18n={i18n(language)}>
        <ExperimentsProvider options={{ experiments }}>
          <Widget
            searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
            viewMode={viewMode}
            isDemoContent={isDemoContent}
            isMobile={isMobile}
            documentTypes={documentTypes}
            settings={settings}
            searchRequest={searchRequest}
            searchRequestStatus={searchRequestStatus}
            searchResponse={searchResponse}
            searchResponseTotals={searchResponseTotals}
            searchSamples={searchSamples}
            onQuerySubmit={onQuerySubmit}
            onPageChange={onPageChange}
            onDocumentTypeChange={onDocumentTypeChange}
            onDocumentClick={onDocumentClick}
            sortConfig={sortConfig}
            locale={language}
            reportError={reportError}
          />
        </ExperimentsProvider>
      </I18nextProvider>
    );
  }
}
