import { initI18n } from '@wix/wix-i18n-config';
import localeData from '../assets/locales/localesData';

// bundle in fallback language
import messagesEn from '../assets/locales/messages_en.json';

declare var __webpack_public_path__: string;

function asyncMessagesLoader(language: string) {
  if (language === 'en') {
    return Promise.resolve(messagesEn);
  }

  // locales are `fetch`ed and not `import`ed because
  // `bolt` SSR enviroment doesn't support webpack's `JsonpTemplatePlugin`
  // see also:
  //   https://github.com/wix-private/site-search/pull/369
  //   https://github.com/wix-private/site-search/commit/93a16dfbe1fcca9af7cc1abe88f0e0df222970c8
  return fetch(
    `${__webpack_public_path__}assets/locales/messages_${language}.json`,
  ).then(r => {
    if (!r.ok) {
      throw new Error(`Can't load locale: ${language}`);
    }

    return r.json();
  });
}

export default (language: string) =>
  initI18n({
    locale: language,
    // TODO: cleanup when resolved https://github.com/wix-private/fed-infra/pull/1343
    // @ts-ignore
    localeData,
    asyncMessagesLoader,
  });
